import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faArrowsAlt, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';
import { shortQueries } from '../queries';
import { Strings } from '../App';

const buttonStyle = {
    padding: 10,
    position: 'relative',
    float: 'right',
    background: '#2BE396',
    borderRadius: 10,
    minWidth: 70,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 'bold'
}

export const MeasurItPanZoomTour = (props) => {

    const short = useMediaQuery(json2mq(shortQueries));

    const strings = useContext(Strings)


    const { tourPages, pageLogo } = strings || {}
    const { mapInstructions } = tourPages || {}
    const {
        instructionText,
        image,
        buttonText
    } = mapInstructions || {}

    const textStyle = {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: short ? 14 : 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }

    return (
        <div style={textStyle} >
            {pageLogo ? <img src={pageLogo.url} alt="measureit-welcome" style={{height: pageLogo.height, width: pageLogo.width}} /> : null }
            <br/>
            {instructionText ? <div dangerouslySetInnerHTML={{__html: instructionText}} />: <p style={short ? {width: 225, fontSize: 14} : {}}>
    Drag {<FontAwesomeIcon icon={faArrowsAlt} />} the map and use the zoom tools {<FontAwesomeIcon icon={faSearchPlus} />}to position your property.
             Lock the map {<FontAwesomeIcon icon={faLock} />} to begin estimating.
            </p>}
            { !short && image ?  <img className='pan-n-zoom-image' src={image.url} alt="animate pan zoom" style={{width: image.width, height: image.height, justifySelf: 'center', alignSelf: 'center'}} /> : null }

            {!short && !image ?  <img  className='pan-n-zoom-image' src="/measurit-pan-zoom.gif" alt="animate pan zoom" style={{width: 300, justifySelf: 'center', alignSelf: 'center'}} /> : null}
            
            <button className="action-button" onClick={() => props.closeTour(false)} style={buttonStyle}>{buttonText}</button>
        </div>
    )
}

MeasurItPanZoomTour.propTypes = {
    closeTour: PropTypes.func.isRequired
}